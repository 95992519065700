import { get, postJson, put, del } from './http.js'

/**
 * 添加、删除收藏
 */
export const toggleFavorites = (id, type) => put(`/article-fav/v1.0/article/${id}/${type}`)

/**
 * 添加、删除点赞
 */
export const articleLike = (id) => put(`/article-fav/v1.0/article/${id}/like`)

/**
 * 置顶、取消置顶
 */
export const toggleTop = (id) => put(`/union/article_favorites/v1.0/article/${id}/top`)

/**
 * 自己的收藏列表
 */
export const getMineFavorites = (pageNum, pageSize) => {
    let params = {
        pageNum,
        pageSize
    }
    return get('/union/article_favorites/v1.0/mine', params)
}

/**
 * 添加评论
 */
export const insertCommentInfo = (id, content) => {
    let params = {
        content
    }
    return postJson(`/article-comment/v1.0/${id}`, params)
}

/**
 * 删除评论
 */
export const deleteCommentInfo = id => del(`/article-comment/v1.0/${id}`)

/**
 * 评论点赞
 */
export const toggleLike = id => put(`/article-comment/v1.0/${id}/like`)

/**
 * 自己的评论列表
 */
export const getMineComments = (pageNum, pageSize) => {
    let params = {
        pageNum,
        pageSize
    }
    return get(`/article-comment/v1.0/mine`, params)
}

/**
 * 添加反馈
 */
export const inserFeedback = (feedback) => {
    let params = {
        feedback
    }
    return postJson(`/feedback/v1.0`, params)
}

/**
 * 自己的回复数量
 */
export const getUserReadTips = () => get(`/article-comment/v1.0/red-user`)

/**
 * 专家的文章评论列表
 */
export const getArticleComments = (pageNum, pageSize) => {
    let params = {
        pageNum,
        pageSize
    }
    return get(`/article-author/v1.0/comments`,params)
}

/**
 * 精选，取消精选评论
 */
 export const articleSelected = (id) => put(`/article-author/v1.0/${id}/selected`)

/**
 * 评论回复
 */
export const insertReply = (id, content) => {
    let params = {
        content
    }
    return postJson(`/article-author/v1.0/${id}/reply`, params)
}

/**
 * 删除回复
 */
export const deleteReply = (id) => del(`/article-author/v1.0/${id}`)

/**
 * 文章评论详情头部
 */
export const getArticle = (articleId) => get(`/article-author/v1.0/article/${articleId}`)

/**
 * 文章评论详情列表
 */
export const getCommentDetails = (articleId, pageNum, pageSize) => {
    let params = {
        pageNum,
        pageSize
    }
    return get(`/article-author/v1.0/article/${articleId}/comment-details`,params)
}

/**
 * 作者文章评论阅读提示
 */
export const getAuthorReadTips = () =>  get(`/article-author/v1.0/red-author`)
